import { Account, Product } from "typings/shared";

export const isProductEnabledForAccount = (
  product: Product,
  account?: Account,
) => {
  if (!account || !account.enabled_products || !product) {
    return false;
  }

  return account.enabled_products.includes(product);
};
