import { ReactNode } from "react";

import { CustomField } from "./models/custom-field";
import { StripeCreditAccount } from "./models/stripe-credit-account";

export type Name = {
  first_name: string;
  last_name: string;
};

export type AccessCode = {
  access_code: string;
};

export type Email = {
  email: string;
};

export type Password = {
  password: string;
};

export type AccountAddress = {
  billing_address_line_1: string;
  billing_address_line_2: string | undefined;
  billing_address_city: string;
  billing_address_state: string;
  billing_address_postal_code: string;
};

export enum CompanyStructureOptions {
  SoleProprietorship = "sole_proprietorship",
  SingleMemberLlc = "single_member_llc",
  MultiMemberLlc = "multi_member_llc",
  PrivateCorporation = "private_corporation",
}

export type CompanyStructure = {
  company_structure: CompanyStructureOptions;
};

export type TAccountingPayoutPolicy = "simple" | "advanced";

export enum Product {
  TREASURY = "treasury",
  CREDIT = "credit",
  LINK = "link",
  PAYMENT_LINK = "payment_link",
}

export type Account = AccountAddress & {
  id: number;
  stripe_account_id: string;
  stripe_requirements_completed: boolean;
  remaining_requirements: WelcomeFlowStep[];
  pms_name: PmsNames;
  pms_accounting_enabled?: boolean;
  name: string;
  is_mercoa_ready: boolean;
  is_checkbook_ready: boolean;
  is_rutter_enabled: boolean;
  is_accounting_enabled: boolean;
  is_finance_integrations_enabled: boolean;
  accounting_platform?: AccountingPlatform;
  accounting_class_enabled: boolean;
  accounting_department_enabled: boolean;
  accounting_payout_policy: TAccountingPayoutPolicy;
  created_at_utc: string;
  debit_cashback_rate: number;
  credit_cashback_rate: number;
  stripe_credit_account: StripeCreditAccount | null;
  single_use_transaction_context_link?: boolean;
  is_approved_virtual_card_authorization_flows_enabled: boolean;
  card_expense_auto_reminder_days: number;
  custom_fields: CustomField[];
  enabled_products?: Product[];
  limits?: {
    [key: string]: number | string;
  };
  transaction_context_fields: string[];
  pms_skip_business_transactions?: boolean;
  pms_include_merchant_in_description?: boolean;
};

export type AccountLimits = {
  outbound_payments_per: number;
  outbound_transfers_per: number;
};

type AccountingPlatform =
  | "quickbooks"
  | "quickbooks_desktop"
  | "netsuite"
  | "sage_intacct"
  | "xero";

type PmsNames =
  | "owner_rez"
  | "hostaway"
  | "track"
  | "guesty"
  | "streamline"
  | "breezeway"
  | "buildium";

export type TSubaccountPurpose = "operating" | "trust";
export type TSubaccountStatus = "active" | "frozen" | "closed" | "pending";
export type TSubaccountDetails = {
  account_holder_name: string;
  account_number: string;
  account_number_last4: string;
  // balance: number; // don't use this balance
  bank_name: string;
  routing_number: string;
};

export type TSubaccount = {
  id: number;
  account_id: number;
  stripe_financial_account_id: string;
  last_4: string;
  balance: number;
  nickname: string;
  purpose: TSubaccountPurpose;
  status: TSubaccountStatus;
  name: string;
  details: TSubaccountDetails;
  rutter_sync_status: "synced" | "queued" | "failed" | null;
  type: "checking" | "credit";
  money_movement_enabled: boolean;
  cards_enabled: boolean;
};

export type TRecipient = {
  id: number;
  account_id: number;
  label: string;
  email: string | null;
  role: string | null;
  last_paid_at_utc: string;
  created_at_utc: string;
  deleted_at_utc: string;
  stripe_customer_id: string;
  stripe_customer_data: {
    description: string;
    name: string;
    address: {
      line1: string;
      line2: string | null;
      city: string;
      state: string;
      postal_code: string;
      country: string;
    };
  };
  stripe_payment_method_id: string;
  stripe_payment_method_data: {
    us_bank_account: {
      account_type: PaymentDestinationAccountTypes;
      account_holder_type: PaymentDestinationAccountHolderTypes;
      bank_name: string;
      last4: string;
      routing_number: string;
      networks: {
        preferred: string;
        supported: string[];
      };
    };
    billing_details: {
      address: {
        line1: string;
        line2: string | null;
        city: string;
        state: string;
        postal_code: string;
        country: string;
      };
    };
  };
  mercoa_entity_id?: string;
  payment_method_type: "bank_transfer" | "check" | "pending";
  shipping_address_line_1: string;
  shipping_address_line_2: string | null;
  shipping_address_city: string;
  shipping_address_state: string;
  shipping_address_postal_code: string;
  shipping_address_country: string;
  rutter_vendor_id: string | null;
};

export type TSimpleAddress = {
  line_1: string;
  line_2: string | undefined | null;
  city: string;
  state: string;
  postal_code: string;
};
export type TOptionalAddress = {
  line_1?: string | undefined | null;
  line_2?: string | undefined | null;
  city?: string | undefined | null;
  state?: string | undefined | null;
  postal_code?: string | undefined | null;
  country?: string | undefined | null;
};
export type TAddress = {
  address_line_1: string;
  address_line_2: string | undefined | null;
  address_city: string;
  address_state: string;
  address_postal_code: string;
};

export type TPropertyAddress = TAddress;

export type Property = TPropertyAddress & {
  account_id: number;
  card_id: number;
  created_at_utc: string;
  deleted_at_utc: null;
  id: number;
  name: string;
  updated_at_utc: string;
  uuid: string;
  nickname?: string;
  pms_property_id?: string;
  // verify these - not originally declared but expected elsewhere:
  zip?: number;
};

export type PropertyView = Property & {
  total: number;
};

export type WelcomeFlowStep =
  | "terms_and_conditions"
  | "congrats"
  | "link_external_bank_account"
  | "fund_account"
  | "income"
  | "credit_onboarding";

export type UserFlowStep =
  | "terms_and_conditions"
  | "credit_terms_and_conditions";

export type HostfiUserRoles = "admin" | "card_only" | "finance" | "bookkeeper";

export type HostfiUser = {
  id: number;
  account_id: number;
  auth0_user_id: string;
  created_at_utc: string;
  deleted_at_utc?: string;
  email: string;
  first_name: string;
  last_name: string;
  nickname: string;
  updated_at_utc: string;
  uuid: string;
  phone?: string;
  role: HostfiUserRoles;
  remaining_requirements: UserFlowStep[];
};

export type HostfiUserPermissions = {
  accounting: number;
  payments_visibility: number;
  card_visibility: number;
  dashboard_visibility: number;
  manage_recipients: number;
  money_movement: number;
  property_visibility: number;
  transaction_visibility: number;
  subaccount_visibility: number;
  integrations_visibility: number;
  documents_visibility: number;

  can_add_or_edit_cards: number;
  can_add_or_edit_properties: number;
  can_add_or_edit_subaccounts: number;
  can_view_card_numbers: number;
};

export class HostfiPagePermissions {
  dashboard_visibility: number;

  transaction_visibility: number;

  property_visibility: number;

  card_visibility: number;

  payments_visibility: number;

  accounting: number;

  subaccount_visibility: number;

  integrations_visibility: number;

  documents_visibility: number;

  constructor(dto: HostfiUserPermissions) {
    this.dashboard_visibility = dto.dashboard_visibility;
    this.transaction_visibility = dto.transaction_visibility;
    this.property_visibility = dto.property_visibility;
    this.card_visibility = dto.card_visibility;
    this.payments_visibility = dto.payments_visibility;
    this.accounting = dto.accounting;
    this.subaccount_visibility = dto.subaccount_visibility;
    this.integrations_visibility = dto.integrations_visibility;
    this.documents_visibility = dto.documents_visibility;
  }
}

export interface FormViewProps {
  onSuccess?: (data: any) => void;
  onError?: (e?: any) => void;
  beforeSubmit?: () => void;
  isLoading?: boolean;
}

export interface FormViewModalProps extends FormViewProps {
  isOpen: boolean;
  onClose: () => void;
}

export type TSpendingLimitInterval =
  | "per_authorization"
  | "daily"
  | "weekly"
  | "monthly"
  | "yearly"
  | "all_time";
export type TSpendingLimit = {
  amount: number;
  categories?: string[];
  interval: TSpendingLimitInterval;
};
export type TSpendingAmounts = {
  daily: number;
  weekly: number;
  monthly: number;
};
export type TSpendingControls = {
  spending_limits: TSpendingLimit[];
  spending_limits_currency?: "usd";
  allowed_categories?: any;
  blocked_categories?: any;
  allowed_merchant_countries?: any;
};
export type TCardStatus = "active" | "inactive" | "canceled";
export type TCardType = "virtual" | "physical";
export type TCard = {
  account_id: number;
  created_at_utc: string;
  deleted_at_utc: string;
  id: number;
  stripe_card_id: string;
  updated_at_utc: string;
  uuid: string;
  properties: Property[];
  type: TCardType;
  status?: TCardStatus;
  label: string;
  last_4?: string; // exists for virtual as well?
  spending_controls: TSpendingControls;
  shipping_address: any;
  stripe_card_json?: StripeCardJson;
  user_id: number;
  subaccount_id: number;
  spend_type: "debit" | "credit";
  temporary_limit: number;
  temporary_limit_expires_at_utc: string;
  property_id: number | null;
  spending_amounts?: TSpendingAmounts | null;
};
export type CardHolder = {
  name: string;
};
export type StripeCardJson = {
  last4?: string;
  cardholder: CardHolder;
};
export type StripeTransactionStatus = "open" | "posted" | "void";
export type StripeTransactionAuthorizationStatus =
  | "pending"
  | "reversed"
  | "closed";

export interface ApiCardTransaction {
  stripe_authorization_id: number | string;
  stripe_authorization_json: any;
  account_id: number;
  amount: number;
  approved: boolean;
  card_id: number;
  created_at_utc: string;
  deleted_at_utc: string;
  id: number;
  merchant_category_code: string;
  merchant_category: string;
  merchant_city: string;
  merchant_country: string;
  merchant_name: string;
  merchant_network_id: number | string;
  merchant_postal_code: string;
  merchant_state: string;
  stripe_authorization_status: StripeTransactionAuthorizationStatus;
  transaction_id: number;
  transaction: ApiBaseTransaction;
  updated_at_utc: string;
  uuid: string;
  card: TCard;
  subaccount_id: TSubaccount["id"];
}

export interface ApiBaseTransaction {
  account_id: number;
  amount: number;
  card?: TCard;
  card_transaction?: Omit<ApiCardTransaction, "transaction">;
  created_at_utc: string;
  deleted_at_utc: string;
  id: number;
  property_id: number | null;
  property: Property;
  stripe_transaction_id: number | string;
  stripe_transaction_json: any;
  stripe_transaction_status: StripeTransactionStatus;
  stripe_transaction_description: string;
  stripe_transaction_type_id: number | string;
  stripe_transaction_type:
    | "received_debit"
    | "inbound_transfer"
    | "outbound_payment"
    | "debit_reversal"
    | "outbound_transfer"
    | "received_credit"
    | "credit_reversal";
  updated_at_utc: string;
  uuid: string;
  subaccount_id: TSubaccount["id"];
  file_id: number | null;
  notes?: string;
  pms_work_order_id?: string | null;
  is_business_expense?: boolean;
  is_itemized?: boolean;
  line_items?: LineItem[];
  payment: Payment | null;
}

export type Payment = {
  id: number;
  to_recipient_id: number;
  to_recipient: TRecipient;
  account_holder_name: string;
  account_bank_name: string;
  account_number_last_4: string;
  amount: number;
  description: string;
  memo: string;
  network: "ach" | "us_domestic_wire" | "stripe";
  stripe_outbound_payment_json: any;
  checkbook_payment_status: string;
  checkbook_payment_json: any;
};

export type CreateLineItemData = {
  amount: number;
  description: string;
  property_id: number | null;
  rutter_accounting_details: object | null;
  pms_accounting_details: object | null;
};

export type LineItem = {
  id: number;
  account_id: number;
  transaction_id: number;
  card_authorization_id: number;
  amount: number;
  description: string | null;
  property_id: number | null;
  rutter_accounting_details: object | null;
  pms_accounting_details: object | null;
};

interface IStatusTransitions {
  canceled_at?: number;
  failed_at?: number;
  succeeded_at?: number;
}
type ITransferObject =
  | "treasury.outbound_transfer"
  | "treasury.inbound_transfer";
export interface ITransfer {
  id: string;
  object: ITransferObject;
  amount: number;
  cancelable: boolean;
  created: number;
  currency: string;
  description: string;
  failure_details?: string;
  financial_account: string;
  hosted_regulatory_receipt_url: string;
  linked_flows: any; // object
  livemode: boolean;
  metadata: any; // object
  origin_payment_method: string;
  origin_payment_method_details: any; // object
  destination_payment_method: string;
  destination_payment_method_details: any; // object
  returned: boolean;
  statement_descriptor: string;
  status: string;
  status_transitions: IStatusTransitions;
  transaction: string;
}

export type ProviderProps = { children: ReactNode | ReactNode[] };

enum EStripeIssuingAuthorizationStatus {
  PENDING = "pending",
  CLOSED = "closed",
  REVERSED = "reversed",
}

export type IntegrationStatus = "pending" | "active" | "disabled";
export type IntegrationType = "payment_processor";
export type IntegrationPaymentProcessorPlatform = "airbnb" | "stripe";

export type IntegrationPlatformByType = {
  payment_processor: IntegrationPaymentProcessorPlatform;
};

export type IntegrationPlatformOptions = {
  airbnb: {
    email: string;
    password?: string;
  };
  stripe: {
    api_key?: string;
  };
};

export type Integration<
  T extends IntegrationType,
  P extends IntegrationPlatformByType[T],
> = {
  id: number;
  created_at_utc: string;
  updated_at_utc: string;
  deleted_at_utc: string;
  account_id: number;
  type: T;
  platform: P;
  status: IntegrationStatus;
  label: string;
  options: IntegrationPlatformOptions[P];
};

export type Rule = {
  id: number;
  label: string;
  type: string;
  priority: number;
  options: Record<string, any>;
  active: boolean;
};

export type RuleAccountingPlatform = "track" | "quickbooks";

export type AvailableRule = {
  type: string;
  name: string;
  description: string;
  required_options: string[];
  accounting_platforms: RuleAccountingPlatform[];
};

export type TCardAuthorization = {
  id: number;
  account_id: Account["id"];
  card: TCard;
  card_id: TCard["id"];
  amount: number;
  approved: boolean;
  stripe_issuing_authorization_status: EStripeIssuingAuthorizationStatus; // default to PENDING
  merchant_category?: string;
  merchant_category_code?: string;
  merchant_name?: string;
  merchant_network_id?: string;
  merchant_city?: string;
  merchant_state?: string;
  merchant_country?: string;
  merchant_postal_code?: string;
  subaccount_id: TSubaccount["id"];
  property_id: number;
  property: Property;
  created_at_utc: string;
  file_id: number | null;
  notes?: string;
  pms_work_order_id?: string;
  is_business_expense?: boolean;
  is_itemized?: boolean;
  line_items?: LineItem[];
};

export enum PaymentTypeOptions {
  Ach = "ach",
  Wire = "wire",
}

export enum PaymentDestinationAccountTypes {
  Checking = "checking",
  Savings = "savings",
}

export enum PaymentDestinationAccountHolderTypes {
  Individual = "individual",
  Company = "company",
}

export type RutterAccount = {
  id: string;
  platform_id: string;
  account_type: string;
  category: string;
  status: string;
  name: string;
  nominal_code: string;
};

export type RutterClass = {
  id: string;
  platform_id: string;
  name: string;
  status: string;
};

export type RutterDepartment = {
  id: string;
  platform_id: string;
  name: string;
  status: string;
};

export enum PmsTransactionSyncType {
  EXPENSE = "expense",
  PAYMENT = "payment",
}

export type PmsTransaction = {
  id: number;
  created_at_utc: string;
  updated_at_utc: string;
  deleted_at_utc: string;
  account_id: number;
  transaction_id: number;
  sync_status: string;
  skip_syncing: boolean;
  sync_type: PmsTransactionSyncType;
  sync_result: any | null; // object
  sync_details: any; // object
  transaction: ApiBaseTransaction;
};

export enum RutterTransactionSyncType {
  DEPOSIT = "deposit",
  INTRA_TRANSFER = "intra_transfer",
  TRANSFER = "transfer",
  EXPENSE = "expense",
  PAYMENT = "payment",
  PAYOUT = "payout",
}

export type RutterTransaction = {
  id: number;
  created_at_utc: string;
  updated_at_utc: string;
  deleted_at_utc: string;
  account_id: number;
  transaction_id: number;
  sync_status: string;
  skip_syncing: boolean;
  sync_type: RutterTransactionSyncType;
  sync_result: any | null; // object
  sync_details: any; // object
  transaction: ApiBaseTransaction;
};

export type PmsSync = {
  id: string;
  name: string;
  created_at_utc: string;
};

export type RutterSync = {
  id: string;
  name: string;
  created_at_utc: string;
};

export type RutterVendor = {
  id: string;
  platform_id: string;
  vendor_name: string | null;
  contact_name: string | null;
  email: string | null;
  platform_data: {
    DisplayName: string | undefined;
    legalname: string | undefined;
  };
  display_name: string | null; // manually added on the client side from platform_data
};

export type RutterCustomer = {
  id: string;
  platform_id: string;
  customer_name: string;
  contact_name: string;
};

export type RutterInvoice = {
  id: string;
  platform_id: string;
  customer_id: string;
  document_number: string;
  status: string;
  // total_amount: string;  // not used for now
  amount_due: string;
};

export type GuestyVendor = {
  id: number;
  name: string;
};

export type TrackVendor = {
  id: number;
  name: string;
};

export type TrackWorkOrder = {
  id: number;
  summary: string;
};

export type TrackAccount = {
  id: number;
  name: string;
  code: string;
};

export type BreezewayTask = {
  id: number;
  name: string;
};

export type MagicLink = {
  id: number;
  uuid: string;
  expires_at_utc: string;
  type: "transaction_context";
  context: any;
  last_submitted_at_utc?: string;
};

export type CheckboxItem = {
  key: string;
  label: string;
  children?: CheckboxItem[];
  kinds?: string[];
};

export type KeywordItem = {
  label?: string;
  partyId?: number;
  type: string;
  isChecked: boolean;
};

export type DateInputError = {
  from: string;
  to: string;
};

export type FilterItem = {
  key: string;
  filterData: string;
  handleFilterReset: () => void;
  isVisible: boolean;
};

export type KeyLabel = {
  key: string;
  label: string;
};

export type MoneyRange = {
  min: string;
  max: string;
};

export type Option = {
  value: string;
  label: string;
};

export type OwnerRezCategory = {
  id: number;
  name: string;
};

export type StreamlineWorkOrder = {
  id: number;
  name: string;
};

export type HostawayCategory = {
  id: number;
  name: string;
};

export type KeyLabelType = {
  key: string;
  label: string;
  type?: string;
};
