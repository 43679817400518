import React, { ReactNode } from "react";

import { Box, Flex } from "@chakra-ui/react";
import { MoveMoneyButton } from "components/MoveMoneyButton";
import { ProfileButton } from "components/ProfileButton";
import { useFetchAccount } from "network/Account/fetch";
import { Product } from "typings/shared";
import { NavAuthenticated } from "ui/Nav/Authenticated/Nav";
import { isProductEnabledForAccount } from "utils/account";

export const AuthenticatedLayout: React.FC<{
  children?: ReactNode;
  searchComponent?: ReactNode;
  maxW?: string;
}> = ({ children, searchComponent, maxW }) => {
  const { data: account } = useFetchAccount();
  return (
    <Box position="relative" h="100vh">
      <NavAuthenticated />
      <Box
        bg="light_gray.100"
        pl={{ base: 0, lg: 240 }}
        pb={1}
        minHeight="100vh"
      >
        <Flex maxWidth="100%" mx={{ base: 5, lg: 12 }}>
          <Flex
            maxW={maxW ?? "100%"}
            width="100%"
            mx="auto"
            h={{ base: 5, lg: 20 }}
            alignItems="flex-end"
            justifyContent={searchComponent ? "space-between" : "flex-end"}
          >
            {searchComponent}
            <Flex>
              {isProductEnabledForAccount(Product.TREASURY, account) && (
                <MoveMoneyButton />
              )}
              <ProfileButton />
            </Flex>
          </Flex>
        </Flex>
        <Box
          maxWidth="100%"
          mx={{ base: 5, lg: 12 }}
          mb={{ base: 5, lg: "30px" }}
          pt={{ base: 10, lg: 0 }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};
