import React from "react";

import {
  Box,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { useUserContext } from "context/user";
import { useFetchAccount } from "network/Account/fetch";
import { useGetUserPermissions } from "network/User/get";
import { Product } from "typings/shared";
import { ButtonLogout } from "ui/Buttons";
import {
  isAuthorized,
  LinkWithTarget,
  Route,
  userRoutes,
} from "ui/Nav/Authenticated/Nav";
import theme from "ui/Themes/default";
import { isProductEnabledForAccount } from "utils/account";

const HoverNameInitialsAvatar = ({ name }: { name: string }) => {
  const splitName = name.split(" ");

  const firstNameInitial = splitName[0] ? splitName[0][0] : "";
  const lastNameInitial =
    splitName.length > 1 ? splitName[splitName.length - 1][0] : "";

  return (
    <MenuButton
      aria-label="Options"
      ml={4}
      bg={theme.colors.emerald_mint[300]}
      color={theme.colors.light_gray[100]}
      w="40px"
      h="40px"
      borderRadius="50%"
      fontWeight="semibold"
      fontSize="xs"
      borderStyle="solid"
      borderWidth="0px"
      display="flex"
      justifyContent="center"
      alignItems="center"
      _hover={{ background: theme.colors.emerald_mint[500] }}
    >
      {firstNameInitial}
      {lastNameInitial}
    </MenuButton>
  );
};

export const ProfileButton: React.FC<any> = () => {
  const user = useUserContext();
  const { data: permissions } = useGetUserPermissions();
  const { data: account } = useFetchAccount();

  const isDocumentsVisible = (route: Route) => {
    if (route.pathname === "/documents") {
      return (
        isProductEnabledForAccount(Product.CREDIT, account) ||
        isProductEnabledForAccount(Product.TREASURY, account)
      );
    }
    return true;
  };

  const name = `${user?.first_name} ${user?.last_name}`;

  return (
    <Menu placement="bottom-end">
      <HoverNameInitialsAvatar name={name} />
      <MenuList py={0} zIndex={5000}>
        {userRoutes.map((route) => {
          return (
            isAuthorized(route, permissions) &&
            isDocumentsVisible(route) && (
              <LinkWithTarget
                key={route.pathname}
                href={route.pathname}
                openInNewTab={route.openInNewTab}
              >
                <MenuItem
                  p={5}
                  alignItems="center"
                  _hover={{ textDecoration: "underline" }}
                  {...(route.iconSvg
                    ? {
                        icon: (
                          <Icon
                            w={6}
                            h={6}
                            as={route.iconSvg}
                            display="flex"
                            alignItems="center"
                          />
                        ),
                      }
                    : {})}
                >
                  {route.display}
                </MenuItem>
              </LinkWithTarget>
            )
          );
        })}
        <Box p={5} borderTop="1px solid #eee">
          <ButtonLogout w="100%" />
        </Box>
      </MenuList>
    </Menu>
  );
};
