import React from "react";

import { Box, Button, Heading, Text, VStack } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { AuthenticatedLayout } from "layouts/Authenticated/Nav";
import { useFetchAccount } from "network/Account/fetch";
import { useRutterCreateWithPublicToken } from "network/RutterConnection/createWithPublicToken";
import { useFetchRutterConnection } from "network/RutterConnection/fetchRutterConnection";
import { useRouter } from "next/router";
import { TiArrowSync } from "react-icons/ti";
import { useRutterLink } from "react-rutter-link";
import { Card } from "ui/Card";
import { CustomSpinner } from "ui/Loader/CustomSpinner";
import {
  useConfirmationToast,
  useErrorToast,
} from "utils/useConfirmationToast";

export const rutterPlatformNames = {
  quickbooks: "Quickbooks",
  quickbooks_desktop: "Quickbooks Desktop",
  netsuite: "NetSuite",
  sage_intacct: "Sage Intacct",
  xero: "Xero",
} as const;

export const rutterPlatforms = Object.keys(rutterPlatformNames);

export default function Rutter() {
  const { data: account, refetch: refetchAccount } = useFetchAccount();
  const router = useRouter();
  const { isLoading: accountLoading } = useFetchAccount();
  const {
    data,
    isLoading: rutterConnectionLoading,
    refetch,
  } = useFetchRutterConnection();

  const rutterConnectionCreateWithPublicTokenMutation =
    useRutterCreateWithPublicToken();
  const errorToast = useErrorToast();
  const successToast = useConfirmationToast();
  const { mutate } = rutterConnectionCreateWithPublicTokenMutation;
  const onSuccess = (publicToken: string) => {
    mutate(
      { public_token: publicToken },
      {
        onSuccess: (response: {
          platform:
            | "QUICKBOOKS"
            | "QUICKBOOKS_DESKTOP"
            | "SAGE_INTACCT"
            | "NETSUITE"
            | "XERO";
          isReady: boolean;
        }) => {
          const responsePlatform =
            response.platform.toLowerCase() as keyof typeof rutterPlatformNames;
          successToast({
            title: `Successfully connected to ${rutterPlatformNames[responsePlatform]}.`,
          });
          refetch();
          refetchAccount();
        },
        onError: () => {
          errorToast({
            duration: 7000,
            title: `Error connecting to your accounting platform.`,
          });
        },
      },
    );
  };
  const rutterConfig: {
    publicKey: string;
    onSuccess: (publicToken: string) => void;
  } = {
    publicKey: process.env.NEXT_PUBLIC_RUTTER_KEY ?? "rutter_key",
    onSuccess,
  };
  const { open } = useRutterLink(rutterConfig);

  const connectRutter = () => {
    open();
  };

  if (data || accountLoading || rutterConnectionLoading) {
    if (data && account) {
      router.push(`/accounting/${account.accounting_platform ?? "return"}`);
    }

    return (
      <AuthenticatedLayout>
        <Box
          as={motion.div}
          initial="hidden"
          animate="show"
          exit="hidden"
          display="flex"
          alignItems="center"
          justifyContent="center"
          zIndex="4000"
          position="absolute"
          top="50%"
          left="50%"
        >
          <CustomSpinner />
        </Box>
      </AuthenticatedLayout>
    );
  }

  return (
    <AuthenticatedLayout>
      <Heading w="100%" margin="auto" mb={7}>
        Accounting
      </Heading>
      <Card>
        <VStack mt={3} justifyContent="center" gap={5}>
          <TiArrowSync size="50px" />
          <Heading size="lg">
            {" "}
            Sync your Accountable transactions at lightning speed.{" "}
          </Heading>
          <Text fontSize="md">
            Connect your accounting provider and say goodbye to logging
            transactions one by one.
          </Text>
          <Button onClick={connectRutter}>Get Started</Button>
          <Text fontSize="sm" mb={5} maxW="500px" mx="auto">
            Accountable won’t automatically sync any data when you connect.
          </Text>
        </VStack>
      </Card>
    </AuthenticatedLayout>
  );
}
