import { routes } from "network/routes";
import { useHfQuery } from "network/useHfQuery";

export const queryKey = "usePmsTransactionCountNotYetSynced";

export const usePmsTransactionCountNotYetSynced = (isEnabled = true) => {
  return useHfQuery<{
    expense: number;
    payment: number;
    total: number;
  }>(queryKey, routes.pmsTransactionCountNotYetSynced, {
    data: {},
    options: {
      enabled: isEnabled,
    },
  });
};
